export const formControlStyle = {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
  };

  export const selectStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: '150px',
    backgroundColor: "#FFFFFF"
  };

  export const formatSupportLevel = (supportLevel) => {
      if (!supportLevel) return '-';
      return supportLevel
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
  };

  export const formatContactName = (contact) => {
    const { first_name, middle_name, last_name } = contact || {};
    return [first_name, middle_name, last_name].filter(name => name).join(' ');
};

export const getFullName = (firstName, lastName) => {
  return [firstName, lastName].filter(name => name)?.join(' ');
};